var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @description A module for parsing ISO8601 durations
 */

/**
 * The pattern used for parsing ISO8601 duration (PnYnMnDTnHnMnS).
 * This does not cover the week format PnW.
 */
// PnYnMnDTnHnMnS

var numbers = "\\d+(?:[\\.,]\\d+)?";
var weekPattern = "(" + numbers + "W)";
var datePattern = "(" + numbers + "Y)?(" + numbers + "M)?(" + numbers + "D)?";
var timePattern = "T(" + numbers + "H)?(" + numbers + "M)?(" + numbers + "S)?";
var iso8601 = "P(?:" + weekPattern + "|" + datePattern + "(?:" + timePattern + ")?)";
var objMap = ["weeks", "years", "months", "days", "hours", "minutes", "seconds"];
/**
 * The ISO8601 regex for matching / testing durations
 */

var pattern = exports.pattern = new RegExp(iso8601);
/** Parse PnYnMnDTnHnMnS format to object
 * @param {string} durationString - PnYnMnDTnHnMnS formatted string
 * @return {Object} - With a property for each part of the pattern
 */

var parse = exports.parse = function parse(durationString) {
  // Slice away first entry in match-array
  return durationString.match(pattern).slice(1).reduce(function (prev, next, idx) {
    prev[objMap[idx]] = parseFloat(next) || 0;
    return prev;
  }, {});
};
/**
 * Convert ISO8601 duration object to an end Date.
 *
 * @param {Object} duration - The duration object
 * @param {Date} startDate - The starting Date for calculating the duration
 * @return {Date} - The resulting end Date
 */


var end = exports.end = function end(duration, startDate) {
  // Create two equal timestamps, add duration to 'then' and return time difference
  var timestamp = startDate ? startDate.getTime() : Date.now();
  var then = new Date(timestamp);
  then.setFullYear(then.getFullYear() + duration.years);
  then.setMonth(then.getMonth() + duration.months);
  then.setDate(then.getDate() + duration.days);
  then.setHours(then.getHours() + duration.hours);
  then.setMinutes(then.getMinutes() + duration.minutes); // Then.setSeconds(then.getSeconds() + duration.seconds);

  then.setMilliseconds(then.getMilliseconds() + duration.seconds * 1000); // Special case weeks

  then.setDate(then.getDate() + duration.weeks * 7);
  return then;
};
/**
 * Convert ISO8601 duration object to seconds
 *
 * @param {Object} duration - The duration object
 * @param {Date} startDate - The starting point for calculating the duration
 * @return {Number}
 */


var toSeconds = exports.toSeconds = function toSeconds(duration, startDate) {
  var timestamp = startDate ? startDate.getTime() : Date.now();
  var now = new Date(timestamp);
  var then = end(duration, now);
  var seconds = (then.getTime() - now.getTime()) / 1000;
  return seconds;
};

exports.default = {
  end: end,
  toSeconds: toSeconds,
  pattern: pattern,
  parse: parse
};
export default exports;
export const __esModule = exports.__esModule;
const _pattern = exports.pattern,
      _parse = exports.parse,
      _end = exports.end,
      _toSeconds = exports.toSeconds;
export { _pattern as pattern, _parse as parse, _end as end, _toSeconds as toSeconds };